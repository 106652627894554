/* Add this to your RegistrationForm.css */
.class-registration {
  background: #f9f9f9;
  margin: 0px 20px;
  padding: 20px;
}

.custom-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line on smaller screens */
  gap:0 4em;
}

.form-group input[type="email"],
.form-group input[type="text"],
.form-group input[type="number"],
.form-group input[type="tel"] {
  width: 18em;
}

.form-group textarea {
  width: 100%;
  overflow-y: auto;
  /* Allows scrolling if text exceeds the textarea height */
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
}


.form-group label {
  margin-bottom: 5px;
}

.form-group input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Quicksand', sans-serif;
}

.inline-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.inline-group input[type="checkbox"],
.inline-group input[type="radio"] 
{
  margin-right: 10px;
  margin-bottom: 5px;
}


input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #006d6a;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;
}

input[type="checkbox"]:checked {
  background-color: #006d6a;
}

input[type="checkbox"]:checked::after {
  content: '✓';
  color: white;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 4px;
}

/* Customize the radio button */
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #006d6a;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
}

input[type="radio"]:checked {
  background-color: #006d6a;
}

input[type="radio"]:checked::after {
  content: '';
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
}


.submit-button {
  padding: 0.75em;
  color: #fff;
  background-color: #006d6a;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 8em;
  /* Adjust the width as needed */
  font-family: 'Quicksand', sans-serif;
  font-size: medium;
  font-weight: bold;
}

.submit-button:hover {
  background-color: #004f4d;
}


.class-info h2 {
  margin-bottom: 20px;
  text-align: left;
}

.class-info p {
  margin: 10px 10px;
  display: flex;
  align-items: center;
  text-align: left;
  gap:1em;
}

.icon {
  color: #006d6a;
}

.class-info a {
  color: inherit;
  text-decoration: none;
}

.class-info a:hover {
  text-decoration: underline;
}


.links {
  margin-top: 1em;
  text-align: left;
}

.links a {
  color: #006d6a;
  text-decoration: none;
}

.links a:hover {
  text-decoration: underline;
}