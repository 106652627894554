/* Yoga.css */
.yoga {
    padding: 20px 20px;
    margin: 0 20px; /* Add this line */
    background: #f9f9f9;
    display: flex; /* Enables Flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
   
    
}

.yoga-module{
    max-width: 900px;    
}

.yoga-module img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto;
    padding: 1%; /* Adjust the padding as needed */
    border-radius: 5%;
}

.yoga-module h1 {
    margin-bottom: 20px;
    text-align: center;
    color: #006d6a;
}

.yoga-module h2 {
    text-align: center;
    margin-top: 4%;

}

.yoga-module h3 {
    text-align: center;
    margin-top: 20px;
    color: gray;
}

.yoga-module ul {
    list-style: none; /* Remove default list styling */
    padding: 0;
    display: flex; /* Use flexbox layout */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center-align items */
}

.yoga-module li {
    text-align: center;
    margin-top: 10px;
    position: relative; /* Needed for positioning the pseudo-element */
}

.yoga-module li::before {
    content: '•'; /* Unicode character for bullet */
    position: absolute;
    left: -20px; /* Adjust as needed to position the bullet to the left of the text */
    color: black; /* Bullet color */
}

.video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    display: flex;
    justify-content: center;
    margin-top: 40px;
    border-radius: 2%;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}