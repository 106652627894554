.event-class{
    padding: 20px 20px;
    margin: 0 20px; /* Add this line */
    display: block;
    background: #f9f9f9;
}

.event-class h1{
    color: #006d6a;
    text-align: center;
}

.event-class h4{
    color: gray;
    text-align: center;
}

.container {
    display: grid;
    grid-template-columns: 0.6fr 0.4fr; 
    gap: 20px; /* Space between the columns */
    padding: 20px;
    align-content: center;
    justify-content: center;
}

.event-details {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-width: 300px;
    text-align: center;
}

.event-details p{
    text-align: left;
}

.event-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    min-width: 300px;
}

/* Media query for tablets and mobile devices */
@media (max-width:1092px){
    .container {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center items horizontally */
    }
}

img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto;
}

