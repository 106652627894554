.yoga-class{
        padding: 20px 20px;
        margin: 0 20px; /* Add this line */
        display: block;
        background: #f9f9f9;
}
.yoga-class h1{
    color: #006d6a;
    text-align: center;

}

.yoga-class h4{
    color: gray;
    text-align: center;

}

.yoga-class p{
    text-align: center;

}


.container {
    display: grid;
    grid-template-columns: 0.6fr 0.4fr; /* Main content on the left, fixed width on the right */
    gap: 20px; /* Space between the columns */
    padding: 20px;
}

.yoga-details {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-width: 300px;
}

.yoga-details p{
    text-align: left;
    

}

.class-details {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-width: 300px;
}

/* Media query for tablets and mobile devices */
@media (max-width: 1024px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center items horizontally */
    }

    .yoga-details {
        order: -1; /* Ensure .yoga-details is below .class-details */
        align-items: center;
    }

    .class-details {
        order: 0; /* Move .class-details to the top */
        position: static; /* Remove the sticky positioning */
        margin-bottom: 20px; /* Add some space between the sections */
        align-items: center;
    }

   
}

.class-details h1 {
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.class-details h4 {
    margin-bottom: 10px;
    margin-left: 5%;
}

.class-details p {
    margin: 10px 10px;
    display: flex;
    align-items: center;
    text-align: left;
    gap:1em;
}

.class-details img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto;
    padding: 1%; /* Adjust the padding as needed */
}

.icon {
    margin-right: 5%;
    margin-left: 5%;
    color: #006d6a;
}

.class-details a{
    color: inherit;
    text-decoration: none;
}

.class-details a:hover {
    text-decoration: underline;
}



.links {
    margin-top: 1em;
    text-align: left;
}

.links a {
    color: #006d6a;

}

.links a:hover {
    text-decoration: underline;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto;
}

.register-button{
    display: block;
    width: 70%;
    margin: 20px auto 0; /* Center horizontally */
    padding: 10px 20px;
    font-size: 16px;
    color: #006d6a;
    background-color:#e6f2f2 ; /* Dark teal color */
    border: 2px solid #006d6a;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
}

.register-button:hover {
    background-color:#006d6a;
}