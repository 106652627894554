.intro-section {
  background: #f9f9f9;
  margin: 0px 20px;
  padding: 20px;
  display: flex;
}
  .overlay {
    /* background: rgba(0, 0, 0, 0.1);*/
    background: #e6f2f2; 
    text-align: center;
    width: 100%;
    border-radius: 10px;
  }