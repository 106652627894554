/* src/Contact.css */
.contact-us {
  background: #f9f9f9;
  margin: 0px 20px;
  padding: 20px;
}

.contact-container {
  display: grid;
  grid-template-columns: 1fr 300px;
  padding: 20px;
  gap: 20px;
}

.card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.contact-form {
  min-width: 300px;
  margin: 0px;

}

.error {
  color: red;
  font-size: 0.7rem;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

.contact-info h3 {
  border-radius: 10px; /* Adjust the radius as needed */
  padding: 10px; /* Add some padding to ensure the text doesn't touch the edges */
  border: 2px solid #006d6a;
  color: #006d6a;
  background-color: white;
}

.contact-info div {
  display: flex;
  gap: 1em;
}

@media (max-width: 800px) {
  .contact-container {
    grid-template-columns: 1fr;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .contact-form {
    min-width: 100%;
    align-items: center;
    display: block;
  }
  .contact-info {
    min-width: 100%;
  }
}
