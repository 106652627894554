.policy{
    background: #f9f9f9;
    margin: 0px 20px;
    padding: 20px;
}


.cancellation{
    width : 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.cancellation h1{
    color: #006d6a;
    text-align: center;
}

.cancellation-detail{
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.cancellation-detail li{
    margin: 1em 0;
    font-size: 1.1em;
    font-weight: bold;
    
}