.class-offerings {
  padding: 20px;
  margin: 0 20px; /* Add this line */
  background: #f9f9f9;
  }
  
  .class-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2em;
    justify-content: center;
  }
  
  @media screen and (min-width: 650px) {
    .class-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (min-width: 975px) {
    .class-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (min-width: 1300px) {
    .class-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .class-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    border-radius: 8px;
    padding: 15px;
    text-align: center;
    transition: transform 0.3s;
    min-width: 275px;
    margin: 0 auto;
  }
  
  .class-card:hover {
    transform: scale(1.05);
  }
  
  .class-card h3 {
    margin: 10px 0;
    background-color: #006d6a;
    color: white;
    padding: 10px;
    border-radius: 8px;
  }

  .spacer {
    height: 1.5em; /* Adjust the height as needed to match the h3 element's height */
  } 

  .class-date, .class-location {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #006d6a;
  }
  
  .class-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .button {
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
  }
  
  .learn-more {
    border: 2px solid #006d6a;
    color: #006d6a;
    background-color: white;
  }
  
  .learn-more:hover {
    background-color: #e6f2f2;
  }
  
  .register {
    border: 2px solid #006d6a;
    color: white;
    background-color: #006d6a;
  }
  
  .register:hover {
    background-color: #004f4d;
  }
  