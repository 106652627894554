.footer {
    padding: 20px 20px;
    margin: 0 20px; /* Add this line */
    background: #f9f9f9;
}

.footer-content {
    display: flex;
    
}

.footer-image-wide,
.footer-image-square {
    margin-left:1.5em;
    margin-right: 5em;

}

.footer-image-wide img,
.footer-image-square img {
    max-height: 85px;
    width: auto;
    border-radius: 5px;
}

@media (max-width: 900px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .footer-image-wide,
    .footer-image-square {
        padding: 20px;
    }
    .footer-image-wide img,
    .footer-image-square img {
        max-height: 70px;
    }
}
